
import { defineComponent, PropType } from "vue";
import { Widgets } from "../../types";
export default defineComponent({
  props: {
    items: {
      type: Object as PropType<Widgets[]>,
    },
  },
});
