
import { defineComponent, PropType } from "@vue/runtime-core";
import { Link, Thumbnail } from "../../types";
export default defineComponent({
  props: {
    thumbnail: Object as PropType<Thumbnail>,
    title: String,
    description: String,
    author: Object as PropType<Link>,
    categoryLink: Object as PropType<Link>,
    category: String,
    buttonText: String,
    href: [String, Object],
    date: {
      type: Date,
      default: function () {
        return new Date();
      },
      required: true,
    },
  },
});
