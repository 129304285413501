import { get } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { Casino, AcceptedCountries, Rating, ProCons, Tag, API_Blog } from "../../types";
import { ref, computed, ComputedRef } from "vue";


export default function () {
  const tags = ref<Tag[]>([]);
  const loading = ref<boolean>(true);
  
  const fetchTags = () : Promise<void | Casino> => {
    return get<Tag[]>(GET.BLOG_TAGS).then((result) => {
      loading.value = false;
      tags.value = camelizeKeys(result);
    });
  };

  fetchTags();

  return {
    tags,
    fetchTags,
    loading,
  };
}
