
import { defineComponent, ref, watch } from "vue";
import Hero from "@/components/Hero/Hero.vue";
import fetchBlogs from "../misc/composition/fetchBlogs";
import fetchBlogTags from "../misc/composition/fetchBlogTags";
import { camelCaseToWords } from "../misc/utilis/api/helpers";
import SearchBox from "@/components/SearchBox/SearchBox.vue";
import WidgetList from "@/components/WidgetList/WidgetList.vue";
import Tags from "@/components/Tags/Tags.vue";
import SectionTitle from "@/components/SectionTitle/SectionTitle.vue";
import BoxNews from "../components/BoxNews/BoxNews.vue";
import LoadingPageAnim from "../components/LoadingPageAnim.vue"
import fetchLatestComments from "../misc/composition/fetchLatestComments";
import fetchCategories from "../misc/composition/fetchCategories";

export default defineComponent({
  components: {
    Hero,
    SearchBox,
    WidgetList,
    Tags,
    SectionTitle,
    BoxNews,
    LoadingPageAnim
  },
  setup() {
    const latestComments = ref();
    const fetchedCategories = ref();

    const { blogs, loading } = fetchBlogs();
    const { tags, loading: tagsLoading } = fetchBlogTags();
    document.title = "Blogs - Coinflip";

    watch(blogs, () => {
      latestComments.value = fetchLatestComments();
      fetchedCategories.value = fetchCategories();
    });

    return {
      camelCaseToWords,
      loading,
      blogs,
      tags,
      fetchedCategories,
      tagsLoading,
      latestComments,
    };
  },
});
