
import { defineComponent, reactive, ref } from "vue";
import type { UnwrapRef } from "vue";

interface FormState {
  search: string;
}
export default defineComponent({
  setup() {
    const formRef = ref();
    const formState: UnwrapRef<FormState> = reactive({
      search: "",
    });
    const rules = {
      search: [
        {
          required: true,
          message: "Please input what you want to search.",
          trigger: "blur",
        },
        { min: 3, max: 5, message: "Length should be 3 to 5", trigger: "blur" },
      ],
    };
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          // handle search
        })
        .catch((error : any) => {
          console.log("error", error);
        });
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    return {
      formRef,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      formState,
      rules,
      onSubmit,
      resetForm,
    };
  },
});
