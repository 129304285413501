import { get } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { API_Comment } from "../../types";
import { ref, computed, ComputedRef } from "vue";
// import { find, meanBy } from "lodash";

export default function (limit: number = 6) {
  const comments = ref<API_Comment[]>([]);
  const loading = ref<boolean>(true);

  const fetchComments = () : Promise<void | API_Comment[]> => {
    return get<API_Comment[]>(GET.LATEST_COMMENTS_BLOG + "/5").then((result) => {
      loading.value = false;
      comments.value = camelizeKeys(result);
    });
  };


  fetchComments();

  return {
    comments,
    fetchComments,
    loading,
  };
}
