import { get } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { Blog_Tag } from "../../types";
import { ref } from "vue";

export default function () {
  const categories = ref<Blog_Tag[]>([]);
  const loading = ref<boolean>(true);

  const fetchCategories = (): Promise<void | Blog_Tag[]> => {
    return get<Blog_Tag[]>(GET.BLOG_CATEGORIES).then((result) => {
      loading.value = false;
      categories.value = camelizeKeys(result);
    });
  };

  fetchCategories();
  return {
    categories,
    fetchCategories,
    loading,
  };
}
