import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0c99fd97")
const _hoisted_1 = { class: "widgets-list-container" }
const _hoisted_2 = { class: "widgets-list" }
const _hoisted_3 = { class: "me-1" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "widgets-list-item mb-2",
          key: item
        }, [
          _renderSlot(_ctx.$slots, "default", { item: item }, () => [
            _createVNode(_component_router_link, {
              class: "item",
              to: item.href
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['fas', 'angle-right'],
                  class: "widgets-icon me-2"
                }),
                _createElementVNode("span", _hoisted_3, _toDisplayString(item.name), 1),
                _createElementVNode("span", null, _toDisplayString(item.count), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ], true)
        ]))
      }), 128))
    ])
  ]))
}